function Header({isSpanish}) {
  return (
    <header className="header navbar fixed-top flex-column flex-lg-row bd-navbar animate__intro-reveal-slide-down">
      <div className="container">
        <div className="col-md-4 col-sm-12">
          <small className="small-caption">{isSpanish ?"En alianza con" :"In partnership with"}</small>
          <a
            href={isSpanish ? "https://www.drinkiq.com/es-es" : "https://www.drinkiq.com/en-gb"}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              window.dataLayer.push({
                event: "customEvent",
                eventCategory: "drinkiq_quiz",
                eventAction: "cta_more_info",
                locale: "en-gb",
              });
            }}
          >
            <img src="./assets/img/drinkiq_logo.svg" alt="Drink IQ" />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
